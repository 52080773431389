import {
  ChangeDetectorRef,
  Component,
  DestroyRef,
  HostListener,
  OnInit,
  inject,
} from '@angular/core';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { TealiumUtagService } from './tealium/utag.service';
import { environment } from '../environments/environment';
import { OKTA_AUTH } from '@okta/okta-angular';
import { AdiHeaderModule } from '@alcon/adi-header-lib';
import { AdiFooterModule } from '@alcon/adi-footer-lib';
import { Observable } from 'rxjs/internal/Observable';
import { of } from 'rxjs/internal/observable/of';
import { filter } from 'rxjs/operators';
import { AsyncPipe, Location } from '@angular/common';
import { LoaderComponent } from './shared/components/loader/loader.component';
import { LocalStorageKey } from './shared/constants/local-storage-key.const';
import { HttpClient } from '@angular/common/http';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AdiHomeLoggingService } from './shared/services/adi-home-logging/adi-home-logging.service';
import { LogLevel } from './shared/constants/log-type.consts';
import { WindowLocationService } from './shared/services/window-location-service/window-location.service';
import { AlconAccountSelectorModule } from '@alcon/alcon-account-selector';
import { AdiMarketingLoginPageModule } from './features/adi-marketing-login-page/adi-marketing-login-page.module';
import { LogObject } from './shared/interfaces/logging.interface';
import { AdiUserDetailsService } from './shared/services/adi-user-details/adi-user-details.service';
import { UserDetails } from './shared/interfaces/userDetails.interface';
import { AuthState } from '@okta/okta-auth-js';
import { AdiModulesServiceService } from './shared/services/adi-modules-service/adi-modules-service.service';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    RouterOutlet,
    AdiHeaderModule,
    AdiFooterModule,
    AsyncPipe,
    LoaderComponent,
    AlconAccountSelectorModule,
    AdiMarketingLoginPageModule,
  ],
  providers: [TealiumUtagService],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit {
  private oktaAuth = inject(OKTA_AUTH);
  public host = environment.API_BE_ENDPOINT;
  public currentEnv = environment.ENV;
  public source = 'adi-platform';
  isLoading: boolean = false;
  isRefreshing: boolean = false;
  showFooter: boolean = false;

  isAuthenticated$: Observable<boolean | undefined> = of(false);
  msgFromHeader: string = '';
  loader: boolean = false;
  destroyRef = inject(DestroyRef);
  locale: string = 'en_US';
  currentUrl: string = '';
  previousUrl: string = '';
  validRoutes: string[] = [];
  showAccountSelector: boolean = false;
  showGuestFooter: boolean = false;
  showHeader: boolean = false;
  excludedUrls: string[] = ['/login', '/logout', 'null', '/account-selector'];

  constructor(
    private tealium: TealiumUtagService,
    private location: Location,
    public http: HttpClient,
    public cd: ChangeDetectorRef,
    public router: Router,
    private loggingService: AdiHomeLoggingService,
    private windowLocationService: WindowLocationService,
    private userDetailsService: AdiUserDetailsService,
    private moduleDetails: AdiModulesServiceService
  ) {
    this.setValidRoutes();
    this.trackUrlChanges();
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        if (
          !this.location.path().includes('/login/callback') &&
          !this.location.path().includes('null') &&
          !this.location.path().includes('logout')
        ) {
          let logType;
          let logDetail;

          if (!this.isRouteValid(this.location.path())) {
            logType = LogLevel.WARN;
            logDetail =
              `User Navigated to ${this.location.path()}` +
              '. This is not a valid route hence user is redirected to 404 page not found';
          } else {
            logType = LogLevel.INFO;
            logDetail = `User Navigated to ${this.location.path()}`;
          }
          const logObj: LogObject = {
            eventType: 'User Navigation',
            eventDetail: logDetail,
            logLevel: logType,
          };
          this.loggingService
            .sendFrontendLogs(logObj)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe();
        }
        if (!localStorage.getItem(LocalStorageKey.SELECTED_LANGUAGE)) {
          localStorage.setItem(LocalStorageKey.SELECTED_LANGUAGE, 'en_US');
        }
        this.getLocale();
        this.loadHeaderFooter();
      });
    this.tealium.setConfig({
      account: environment.TEALIUM_ACCOUNT,
      profile: environment.TEALIUM_PROFILE,
      environment: environment.TEALIUM_ENV,
    });
  }

  private trackUrlChanges(): void {
    this.router.events
      .pipe(
        filter(
          (event): event is NavigationEnd => event instanceof NavigationEnd
        )
      )
      .subscribe((event: NavigationEnd) => {
        const url = event.urlAfterRedirects;
        if (!this.isExcludedUrl(url)) {
          localStorage.setItem(LocalStorageKey.LAST_VISITED_PATH, url);
        }
        if (
          !url.includes('/user-management?current') &&
          !url.includes('/baa-template')
        ) {
          if (localStorage.getItem('baa-page-denied') !== 'true') {
            localStorage.removeItem(LocalStorageKey.CURRENT_USER_DETAILS);
          }
        }
        if (!url.includes('baa-template')) {
          sessionStorage.removeItem('accountId');
          sessionStorage.removeItem('secondLastPath');
        }
      });
  }

  private isExcludedUrl(url: string): boolean {
    return this.excludedUrls.some((excludedUrl) => url.includes(excludedUrl));
  }

  setValidRoutes(): void {
    const selectedLanguage =
      localStorage.getItem(LocalStorageKey.SELECTED_LANGUAGE) ?? 'en_US';
    const addValidRoute = (path: string | undefined) => {
      if (path && !path.includes(':') && path !== '**') {
        this.validRoutes.push(`${selectedLanguage}/${path}`);
      }
    };
    this.router.config.forEach((route) => {
      addValidRoute(route.path);
      if (route.children) {
        route.children.forEach((childRoute) => {
          addValidRoute(childRoute.path);
        });
      }
    });
  }

  isRouteValid(path: string): boolean {
    return this.validRoutes.some((route: string) => path === '/' + route);
  }

  @HostListener('window:beforeunload', ['$event'])
  beforeunloadHandler() {
    if (!this.isRefreshing) {
      this.loggingService
        .applicationLoadEventLogs(
          'Application End',
          LogLevel.INFO,
          'User has closed the Application'
        )
        .subscribe();
    }
  }

  @HostListener('window:beforeunload', ['$event'])
  onRefresh(): void {
    this.isRefreshing = true;
  }

  ngOnInit(): void {
    this.tealium.view({ event_name: 'init' });
    if (!this.location.path().includes('logout')) {
      this.loggingService
        .applicationLoadEventLogs(
          'Application Load',
          LogLevel.INFO,
          'Application Loaded Successfully'
        )
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe();
    }

    this.oktaAuth.authStateManager.subscribe((res: AuthState) => {
      if (res.isAuthenticated && !this.location.path().includes('logout')) {
        this.userDetailsService
          .getCurrentUserDetails()
          .pipe(takeUntilDestroyed(this.destroyRef))
          .subscribe((res: UserDetails | undefined) => {
            if (!res) {
              this.checkUserDetailsData();
              this.checkModuleDetailsData();
            }
          });
      }
    });
  }

  checkUserDetailsData() {
    this.userDetailsService.callUserDetails();
  }
  checkModuleDetailsData() {
    this.moduleDetails.callModuleDetails();
  }

  loadHeaderFooter() {
    if (!this.location.path().includes('logout')) {
      this.oktaAuth
        .isAuthenticated()
        .then((res) => {
          this.showHeader = res;
          this.showFooter =
            res && !this.location.path().includes('account-selector');
          this.showGuestFooter =
            this.location.path().includes('account-selector') && res;
        })
        .catch((err) => {
          this.showFooter = false;
          this.showGuestFooter = false;
          this.showHeader = false;
        });
    } else {
      this.showHeader = false;
      this.showFooter = false;
      this.showGuestFooter = false;
    }

    if (
      !this.location.path().includes('account-selector') &&
      !this.location.path()?.includes('facilities-management') &&
      !this.location.path()?.includes('baa-template')
    ) {
      this.showAccountSelector = true;
    } else {
      this.showAccountSelector = false;
    }
  }

  getLocale() {
    let url = window.location.pathname;
    const segments = url.split('/');
    this.locale = segments[1];
    if (!this.location.path().includes('/login/callback')) {
      if (
        this.locale !== localStorage.getItem(LocalStorageKey.SELECTED_LANGUAGE)
      ) {
        segments[1] = 'en_US';
        url = segments.join('/');
        this.windowLocationService.openLink(url);
      }
    }
  }

  showHideLoader($event: boolean) {
    this.loader = $event;
    this.cd.detectChanges();
  }
}
