import { Routes } from '@angular/router';
import { AdiAuthGuard } from './shared/guards/auth-guard/adi-auth-guard.guard';
import { LocalStorageKey } from './shared/constants/local-storage-key.const';
import { AdiNoAuthGuard } from './shared/guards/no-auth-guard/adi-no-auth.guard';
import { AccountSelectorGuard } from './shared/guards/account-selector-guard/account-selector.guard';
import { AccountAuthGuard } from './shared/guards/account-auth-guard/account-auth.guard';

const currentSelectedLang =
  localStorage.getItem(LocalStorageKey.SELECTED_LANGUAGE) ?? 'en_US';
export const routes: Routes = [
  {
    path: '',
    redirectTo: `${currentSelectedLang}/login`,
    pathMatch: 'full',
  },
  {
    path: 'login',
    loadChildren: () =>
      import(
        './features/adi-marketing-login-page/adi-marketing-login-page.module'
      ).then((m) => m.AdiMarketingLoginPageModule),
  },
  {
    path: ':locale',
    children: [
      {
        path: '',
        redirectTo: 'login',
        pathMatch: 'full',
      },
      {
        path: 'login',
        canActivate: [AdiNoAuthGuard],
        loadChildren: () =>
          import(
            './features/adi-marketing-login-page/adi-marketing-login-page.module'
          ).then((m) => m.AdiMarketingLoginPageModule),
      },
      {
        path: 'dashboard',
        canActivate: [AdiAuthGuard, AccountAuthGuard],
        loadChildren: () =>
          import('./features/dashboard/dashboard.module').then(
            (m) => m.DashboardModule
          ),
      },
      {
        path: 'error',
        canActivate: [AdiAuthGuard],
        loadChildren: () =>
          import('./features/error/error.module').then((m) => m.ErrorModule),
      },
      {
        path: 'account-selector',
        canActivate: [AdiAuthGuard, AccountSelectorGuard],
        loadChildren: () =>
          import('./features/account-selector/account-selector.module').then(
            (m) => m.AccountSelectorModule
          ),
      },
      {
        path: 'account',
        canActivate: [AdiAuthGuard, AccountAuthGuard],
        loadChildren: () =>
          import('./features/account/account.module').then(
            (m) => m.AccountModule
          ),
      },
      {
        path: 'contact',
        canActivate: [AdiAuthGuard, AccountAuthGuard],
        loadChildren: () =>
          import('./features/contact-us/contact-us.module').then(
            (m) => m.ContactUsModule
          ),
      },
      {
        path: 'facilities-management',
        canActivate: [AdiAuthGuard, AccountAuthGuard],
        loadChildren: () =>
          import(
            './features/facilities-management/facilities-management.module'
          ).then((m) => m.FacilitiesManagementModule),
      },
      {
        path: 'logout',
        canActivate: [AdiAuthGuard],
        loadChildren: () =>
          import('./features/adi-home-slo/adi-home-slo.module').then(
            (m) => m.AdiHomeSloModule
          ),
      },
      {
        path: '**',
        canActivate: [AdiAuthGuard],
        loadChildren: () =>
          import('./features/error/error.module').then((m) => m.ErrorModule),
      },
    ],
  },
];
